import simpleRestProvider from 'ra-data-simple-rest';
import { makeApiCall } from "./httpClient";

const restServerProvider = simpleRestProvider('', makeApiCall);

const dataProvider = {
    ...restServerProvider,
    getUserRole: () => {
        const { role } = JSON.parse(localStorage.getItem('user'));

        return role;
    },
    sendWelcomeEmail: (type, userId, link) => {
        const resource = type === 'user' ? '/user' : '';

        return makeApiCall(`${resource}/users/${userId}/send-welcome-email`, { method: 'POST' }, {link: link});
    },
    forgotPassword: (email, link) => {
        return makeApiCall(`/forgot-password`, { method: 'POST' }, {email: email, link: link});
    },
    resetPassword: (token, password) => {
        return makeApiCall(`/reset-password`, { method: 'POST' }, {token: token, password: password});
    },
    getIvesRequestStatuses: () => {
        return makeApiCall('/ives-request-statuses', { method: 'GET' });
    },
    getExpandedIvesRequestStatuses: () => {
        return makeApiCall('/ives-request-statuses', { method: 'GET' });
    },
    getInvoiceStatuses: (type) => {
        const resource = type === 'user' ? '/user' : '';

        return makeApiCall(`${resource}/invoice-statuses`, { method: 'GET' });
    },
    getInvoiceableTypes: (type) => {
        const resource = type === 'user' ? '/user' : '';

        return makeApiCall(`${resource}/invoiceable-types`, { method: 'GET' });
    },
    resetInvoice: (invoiceId) => {
        return makeApiCall(`/invoices/reset/${invoiceId}`, { method: 'GET' });
    },
    populateInvoice: (invoiceId) => {
        return makeApiCall(`/invoices/populate/${invoiceId}`, { method: 'GET' });
    },
    getInvoicePreview: (invoiceId) => {
        return makeApiCall(`/invoices/preview/${invoiceId}`, { method: 'GET' });
    },
    sendInvoice: (invoiceId) => {
        return makeApiCall(`/invoices/send/${invoiceId}`, { method: 'POST' });
    },
    exportInvoice: (type, invoiceId) => {
        const resource = type === 'user' ? '/user' : '';

        return makeApiCall(`${resource}/invoices/export/${invoiceId}`, { method: 'GET' });
    },
    exportInvoiceDetails: (type, invoiceId) => {
        const resource = type === 'user' ? '/user' : '';

        return makeApiCall(`${resource}/invoices/export-details/${invoiceId}`, { method: 'GET' });
    },
    setInvoiceStatus: (invoiceId, invoiceStatusId) => {
        return makeApiCall(`/invoices/${invoiceId}`, { method: 'PUT' }, {'invoice_status_id': invoiceStatusId});
    },
    createPayouts: (invoiceId) => {
        return makeApiCall(`/invoices/create-payouts/${invoiceId}`, { method: 'POST' });
    },
    markPayoutAsPaid: (payoutId) => {
        return makeApiCall(`/payouts/${payoutId}`, { method: 'PUT' }, {'payout_status_id': 2});
    },
    getPayoutStatuses: () => {
        return makeApiCall(`/payout-statuses`, { method: 'GET' });
    },
    createIvesRequestBillingMatrix: (ivesRequestBillingMatrix) => {
        return makeApiCall('/ives-request-billing-matrix', { method: 'POST' }, ivesRequestBillingMatrix);
    },
    getIvesRequestBillingMatrix: (ivesRequestBillingMatrixId) => {
        return makeApiCall(`/ives-request-billing-matrix/${ivesRequestBillingMatrixId}`, { method: 'GET' });
    },
    updateIvesRequestBillingMatrix: (ivesRequestBillingMatrixId, ivesRequestBillingMatrix) => {
        return makeApiCall(`/ives-request-billing-matrix/${ivesRequestBillingMatrixId}`, { method: 'PUT' }, ivesRequestBillingMatrix);
    },
    getIvesRequestBillingMatrixValidity: (institutionId) => {
        return makeApiCall(`/ives-request-billing-matrix/check-validity/${institutionId}`, { method: 'GET' });
    },
    createIvesRequestBilling: (ivesRequestBilling) => {
        return makeApiCall('/ives-request-billing', { method: 'POST' }, ivesRequestBilling);
    },
    getIvesRequestBilling: (ivesRequestBillingId) => {
        return makeApiCall(`/ives-request-billing/${ivesRequestBillingId}`, { method: 'GET' });
    },
    updateIvesRequestBilling: (ivesRequestBillingId, ivesRequestBilling) => {
        return makeApiCall(`/ives-request-billing/${ivesRequestBillingId}`, { method: 'PUT' }, ivesRequestBilling);
    },
    getIvesRequestBillingValidity: (institutionId) => {
        return makeApiCall(`/ives-request-billing/check-validity/${institutionId}`, { method: 'GET' });
    },
    getTaxpayerNotificationEmailPreview: (segments) => {
        return makeApiCall('/user/ives-settings/taxpayer-notification-email-preview', { method: 'POST' }, {segments: segments});
    },
    markAnnouncementAsRead: (announcementId) => {
        return makeApiCall(`/user/announcements/${announcementId}/mark-as-read`, { method: 'PUT' });
    },
    getNotifications: () => {
        return makeApiCall(`/user/notifications`, { method: 'GET' });
    },
    markNotificationAsRead: (notificationId) => {
        return makeApiCall(`/user/notifications/${notificationId}/mark-as-read`, { method: 'PUT' });
    },
    deleteNotification: (notificationId) => {
        return makeApiCall(`/user/notifications/${notificationId}`, { method: 'DELETE' });
    },
    enableTaxpayerEmailNotifications: () => {
        return makeApiCall(`/user/ives-settings/enable-taxpayer-email-notifications`, { method: 'POST' });
    },
    sendTaxpayerEmailNotificationRequest: (role, requestId) => {
        if (role === 'user') {
            role = '/user';
        } else {
            role = '';
        }

        return makeApiCall(`${role}/ives-requests/${requestId}/send-taxpayer-email-notification`, {method: 'POST'});
    },
    withdrawRequest: (role, requestId) => {
        if (role === 'user') {
            role = '/user';
        } else {
            role = '';
        }

        return makeApiCall(`${role}/ives-requests/${requestId}/withdraw`, {method: 'DELETE'});
    },
    createInstitutionContact: (institutionContact) => {
        return makeApiCall('/institution-contacts', { method: 'POST' }, institutionContact);
    },
    getInstitutionContact: (institutionContactId) => {
        return makeApiCall(`/institution-contacts/${institutionContactId}`, { method: 'GET' });
    },
    updateInstitutionContact: (institutionContactId, institutionContact) => {
        return makeApiCall(`/institution-contacts/${institutionContactId}`, { method: 'PUT' }, institutionContact);
    },

    getIvesRequestsByMonth: () => {
        return makeApiCall(`/reports/ives-requests-by-month`, { method: 'GET' });
    },
    getIvesRequestsByDay: (startDate, endDate) => {
        return makeApiCall(`/reports/ives-requests-by-day/${startDate}/${endDate}`, { method: 'GET' });
    },
}

export default dataProvider;