import React from "react";
import {usePermissions} from "ra-core";
import {Menu} from 'react-admin';
import SubMenu from './SubMenu';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import FoundationIcon from '@mui/icons-material/Foundation';
import dataProvider from "../Providers/dataProvider";
import ReorderIcon from "@mui/icons-material/Reorder";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaidIcon from "@mui/icons-material/Paid";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StoreIcon from "@mui/icons-material/Store";
import GroupsIcon from '@mui/icons-material/Groups';
import {Divider, Grid} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import QuizIcon from '@mui/icons-material/Quiz';
import AddIcon from '@mui/icons-material/Add';

const PERMISSION_INSTITUTION_ADMINISTRATOR = 'institution-administrator';

export const MainMenu = () => {
    const user = JSON.parse(localStorage.getItem("user")) || {id: -1};
    const role = dataProvider.getUserRole();
    const { permissions } = usePermissions();

    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    return (
        <Menu>
            <Menu.DashboardItem />
            {
                (role === 'Admin' || role === 'User') &&
                <SubMenu primaryText="IVES" leftIcon={<WysiwygIcon/>} isDropdownOpen={true}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Menu.Item to="/user/ives-requests" primaryText="Requests" leftIcon={<ReorderIcon />} />
                        </Grid>
                        <Grid item xs={2}>
                            <Menu.Item to="/user/ives-requests/create" ><AddIcon sx={{ marginLeft: -1.3 }} /></Menu.Item>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}>
                            <Menu.Item to="/user/ives-clients" primaryText="Clients" leftIcon={<GroupsIcon />} />
                        </Grid>
                        <Grid item xs={2}>
                            <Menu.Item to="/user/ives-clients/create" ><AddIcon sx={{ marginLeft: -1.3 }} /></Menu.Item>
                        </Grid>
                    </Grid>
                    <Menu.Item to="/user/ives-settings/1/show" primaryText="Settings" leftIcon={<TuneIcon />} />
                    <Menu.Item to="/user/user-guide" primaryText="User Guide" leftIcon={<QuizIcon />} />
                </SubMenu>
            }
            {
                hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) &&
                <SubMenu primaryText="Admin" leftIcon={<EnhancedEncryptionIcon/>}>
                    <Menu.Item to="/user/branches" primaryText="Branches" leftIcon={<StoreIcon />} />
                    <Menu.Item to="/user/users" primaryText="Users" leftIcon={<ManageAccountsIcon />} />
                    <SubMenu primaryText="Billing" leftIcon={<AttachMoneyIcon/>}>
                        <Menu.Item to="/user/invoices" primaryText="Invoices" leftIcon={<ReceiptIcon />} />
                        <Menu.Item to="/user/branch-credits" primaryText="Branch Credits" leftIcon={<MoneyOffIcon />} />
                        <Menu.Item to="/user/institution-credits" primaryText="Institution Credits" leftIcon={<MoneyOffIcon />} />
                        <Menu.Item to="/user/payments" primaryText="Payments" leftIcon={<PaidIcon />} />
                    </SubMenu>
                </SubMenu>
            }
            {
                role === 'Admin' &&
                <SubMenu primaryText="Admin" leftIcon={<EnhancedEncryptionIcon/>}>
                    <Divider />
                    <SubMenu primaryText="Entities" leftIcon={<FoundationIcon/>}>
                        <Menu.ResourceItem name="institutions" />
                        <Menu.ResourceItem name="branches" />
                        <Menu.ResourceItem name="users" />
                    </SubMenu>
                    <SubMenu primaryText="IVES" leftIcon={<ReorderIcon/>}>
                        <Menu.Item to="/ives-requests" primaryText="Requests" leftIcon={<ReorderIcon />} />
                        <Menu.Item to="/expanded-ives-requests" primaryText="Exp. Requests" leftIcon={<ReorderIcon />} />
                    </SubMenu>
                    <SubMenu primaryText="Billing" leftIcon={<AttachMoneyIcon/>}>
                        <Menu.ResourceItem name="invoices" />
                        <Menu.Item to="/branch-credits" primaryText="Branch Credits" leftIcon={<MoneyOffIcon />} />
                        <Menu.Item to="/institution-credits" primaryText="Institution Credits" leftIcon={<MoneyOffIcon />} />
                        <Menu.ResourceItem name="payments" />
                        {user.id === 1 ? (
                            <Menu.ResourceItem name="payouts" />
                        ) : (<></>)}
                    </SubMenu>
                    <Menu.ResourceItem name="announcements" />
                    {user.id === 1 ? (
                        <>
                            <Menu.ResourceItem name="reports" />
                            <SubMenu primaryText="Advanced" leftIcon={<DisplaySettingsIcon />}>
                                <Menu.ResourceItem name="logs" />
                                <Menu.ResourceItem name="settings" />
                            </SubMenu>
                        </>
                    ) : (<></>)}
                </SubMenu>
            }
            {
                role === 'Affiliate' &&
                <SubMenu primaryText="Affiliate" leftIcon={<EnhancedEncryptionIcon/>}>
                    <Divider />
                    <SubMenu primaryText="Entities" leftIcon={<FoundationIcon/>}>
                        <Menu.ResourceItem name="affiliate/institutions" />
                        <Menu.ResourceItem name="affiliate/branches" />
                        <Menu.ResourceItem name="affiliate/users" />
                    </SubMenu>
                    <SubMenu primaryText="IVES" leftIcon={<ReorderIcon/>}>
                        <Menu.Item to="/affiliate/ives-requests" primaryText="Requests" leftIcon={<ReorderIcon />} />
                    </SubMenu>
                </SubMenu>
            }
        </Menu>
    );
}

export default MainMenu;